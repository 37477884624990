export const document = {
    DOCUMENT_TYPE: {
        "RC": { id: 1, name: "RC" },
        "EMIRATES_ID": { id: 2, name: "EMIRATES_ID" },
        "PASSPORT": { id: 3, name: "PASSPORT" },
        "DRIVING_LICENSE": { id: 4, name: "DRIVING_LICENSE" },
        "CONSIGNMENT_NOTE": { id: 5, name: "CONSIGNMENT_NOTE" },
        "DELIVERY_NOTE": { id: 6, name: "DELIVERY_NOTE" }
    },
    DOCUMENT_STATUS: {
        "Approved": { id: 1, name: "Approved" },
        "Pending": { id: 2, name: "Pending" },
        "Rejected": { id: 3, name: "Rejected" }
    }
}