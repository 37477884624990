export const company = {
  COMPANY_STATUS_ID: {
    Lead: 1,
    Active: 2,
    InActive: 3,
    Blacklisted: 4
  },
  USER_TYPE: {
    CARRIER: 'carrier' as 'carrier',
    SHIPPER: 'shipper' as 'shipper',
    EMPLOYEE: 'employee' as 'employee',
    ADMIN: 'admin' as 'admin'
  },
  OPERATION_TYPE: {
    LOCAL: 'Local',
    CROSS_BORDER: 'Cross border'
  },
  TOKEN_PROCESS_ID: {
    "CARRIER_ADVANCE": 1,
    "SHIPPER_ADVANCE": 2,
    "TRIP_INVOICE": 3,
    "CARRIER_BALANCE": 4,
    "SHIPPER_BALANCE": 5,
    "SHIPPER_WALLET_TOPUP": 6,
    "SHIPPER_WALLET_REVERSE": 7,
    "CANCEL_TRIP_INVOICE": 8,
    "CANCEL_SHIPPER_ADVANCE": 9
  }
}

export const contacts = {
  DEFAULT_CONTACT: {
    UAE_SUPPORT: '+97142576798'
  }
}