export const employee = {

    DEFAULT_MANAGED_BY: {
        SHIPPER: 2,
        CARRIER: 3        
    },

    CONTACT: {
        SHARE: {
            name: "",
            mobile: "+971 42576798"
        }
    }

}